import React from "react";

const ScrollTo = ({ children, className = "" }) => {
  return (
    <div className="max-w-6xl lg:max-w-none flex xl:justify-center overflow-x-scroll px-10 lg:px-20 relative">
      <div className={`mx-auto ${className}`}>{children}</div>
    </div>
  );
};

// `<div className="bg-gradient-to-l from-black to-transparent bottom-0 right-0 w-[30px] h-[100%]"></div>`

export default ScrollTo;
