import * as React from "react";
import Hero from "../components/hero";
import Tail from "../components/tail";
import TextBlock from "../components/text";
import Seo from "../components/seo";

import Header2 from "../components/header/header2";
import Header3 from "../components/header/header3";
import Header4 from "../components/header/header4";

import HistoOld from "../components/histograms/histoold";
import MGCaption from "../components/MGCaption";
import { StaticImage } from "gatsby-plugin-image";
import ImageSlider from "../components/ImageSlider";
import Slide from "../components/lego/slide";
import NaviNext from "../components/NaviNext";
import SinglePhoto from "../components/SinglePhoto";
import ScrollTo from "../components/lego/scrollto";
import Short from "../components/short";

const Part1 = () => {
  return (
    <Tail>
      <Hero>
        <h1>
          РАЗДЕЛ 1. <br />
          ОБЩИЕ СВЕДЕНИЯ
        </h1>
      </Hero>
      <TextBlock>
        <Header2>1.1. МОСГАЗ СЕГОДНЯ</Header2>
        <Header3>1.1.1. ПОЛОЖЕНИЕ ОБЩЕСТВА В ОТРАСЛИ</Header3>
        <p>
          Акционерное общество «МОСГАЗ» – организация, которая играет одну из
          ключевых ролей в сфере обеспечения энергетической безопасности Москвы.
        </p>
        <p>
          Общество является самой крупной газораспределительной организацией на
          территории Восточной Европы и сохраняет уверенные позиции в системе
          жизнеобеспечения столичной агломерации, обеспечивая бесперебойную и
          безопасную подачу газа московским потребителям.
        </p>

        <SinglePhoto>
          <StaticImage
            src="../images/part-01/1.1.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
          />
        </SinglePhoto>

        <p>
          Объем потребления природного газа в Москве в 2021 году составил{" "}
          <strong>
            24 764 млн м<sup>3</sup>
          </strong>
          .
        </p>
        <p>
          Более <strong>4,2 тысячи сотрудников</strong> АО «МОСГАЗ» обеспечивают
          эксплуатацию газовых сетей общей протяженностью более{" "}
          <strong>7500 километров</strong>, 315 газорегуляторных пунктов и более
          3400 электрозащитных установок.
        </p>
        <p>
          Сети АО «МОСГАЗ» протяженностью более 150 км находятся на территории
          ТиНАО города Москвы.
        </p>
        <p>
          Обществом обеспечивается бесперебойное газоснабжение 144 объектов
          генерации тепловой и электрической энергии, более 1400 промышленных и
          коммунально-бытовых предприятий, а также 24 415 жилых строений.
        </p>
      </TextBlock>
      <ScrollTo className="min-w-[450px]">
        <MGCaption>
          В эксплуатации Общества находятся по состоянию на 31.12.2021:
        </MGCaption>

        <div className="table-km max-w-4xl grid grid-cols-6 text-center mb-5">
          <div className="bg-[#8a8b8e] text-center    col-start-1 col-end-7">
            <strong>7591,43 км</strong> - общая протяженность газовых сетей
          </div>

          <div className="bg-[#b3b3b5] text-center    col-start-1 col-end-3">
            <strong>583,04 км</strong> <br />
            высокое давление
          </div>
          <div className=" bg-[#b3b3b5] text-center    col-start-3 col-end-5">
            <strong>1024,43 км</strong> <br />
            среднее давление
          </div>
          <div className="bg-[#b3b3b5] text-center    col-start-5 col-end-7">
            <strong>5983,96 км</strong> <br />
            низкое давление
          </div>
          <div className=" bg-[#dddee0]    col-start-1 col-end-4">
            <strong>4007,12</strong> км подземных газопроводов
          </div>
          <div className=" bg-[#dddee0]    col-start-4 col-end-7">
            <strong>3584,31</strong> км надземных газопроводов
          </div>
          <div className="bg-[#f2e0bc] text-center    col-start-1 col-end-3">
            <strong>1116,90 км</strong>
          </div>

          <div className="   col-start-3 col-end-7">
            подземные стальные газопроводы с превышением нормативного срока
            эксплуатации 40 лет
          </div>
        </div>
      </ScrollTo>
      <ScrollTo className="min-w-[500px]">
        <MGCaption>Сооружения газораспределительной сети АО «МОСГАЗ»</MGCaption>
        <div className="ttable grid max-w-4xl grid-cols-7 mb-5">
          <div className="col-start-1 col-end-7 border-2 border-white bg-slate-200">
            Газораспределительная станция (ГРС), ед.
          </div>
          <div className="text-right border-2 border-white bg-slate-200">1</div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-slate-200">
            Газораспределительные пункты (ГРП), всего, ед.
          </div>
          <div className="text-right border-2 border-white bg-slate-200">
            315
          </div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-white ttable--white">
            Системообразующие ГРП, ед.
          </div>
          <div className="text-right border-2 border-white bg-white">7</div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-white ttable--white">
            ГРП большой мощности, ед.
          </div>
          <div className="text-right border-2 border-white bg-white">28</div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-white ttable--white">
            ГРП средней мощности, ед.
          </div>
          <div className="text-right border-2 border-white bg-white">39</div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-white ttable--white">
            ГРП малой мощности, ед.
          </div>
          <div className="text-right border-2 border-white bg-white">241</div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-slate-200">
            Модульные распределительные пункты блочные (МРПБ), ед.
          </div>
          <div className="text-right border-2 border-white bg-slate-200">1</div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-slate-200">
            Шкафные распределительные пункты (ШРП), ед.
          </div>
          <div className="text-right border-2 border-white bg-slate-200">
            156
          </div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-slate-200">
            Модульные распределительные пункты (МРП), ед.
          </div>
          <div className="text-right border-2 border-white bg-slate-200">5</div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-slate-200">
            Шкафные газорегуляторные установки (ШБДГ), ед.
          </div>
          <div className="text-right border-2 border-white bg-slate-200">
            703
          </div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-slate-200">
            Домовые распределительные пункты (ДРП), ед.
          </div>
          <div className="text-right border-2 border-white bg-slate-200">
            81
          </div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-slate-200">
            Электрозащитные установки (ЭЗУ), всего, ед.
          </div>
          <div className="text-right border-2 border-white bg-slate-200">
            3486
          </div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-white ttable--white">
            Станции катодной защиты
          </div>
          <div className="text-right border-2 border-white bg-white">3399</div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-white ttable--white">
            Дренажная защита
          </div>
          <div className="text-right border-2 border-white bg-white">87</div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-slate-200">
            Запорные устройства на газопроводах высокого и среднего давлений,
            ед.
          </div>
          <div className="text-right border-2 border-white bg-slate-200">
            5054
          </div>

          <div className="col-start-1 col-end-7 border-2 border-white bg-slate-200">
            Запорные устройства на газопроводах низкого давления, ед.
          </div>
          <div className="text-right border-2 border-white bg-slate-200">
            113 325
          </div>
        </div>
      </ScrollTo>
      <TextBlock>
        <Header3>
          Показатели старения газораспределительной сети АО «МОСГАЗ»
        </Header3>
        <MGCaption>Динамика старения подземных газопроводов, км</MGCaption>
      </TextBlock>
      <ScrollTo>
        <HistoOld idkey={"histoold"} />
      </ScrollTo>
      <TextBlock>
        <p>
          В 2021 году АО «МОСГАЗ» отремонтировано 14 электрозащитных установок,
          заменено оборудование на 46 электрозащитных установках, произведен
          ремонт строительной части 30 ГРП, а также ремонт электрооборудования
          на 24 ГРП и технологического оборудования на 2 ГРП.
        </p>
        <p>
          Сегодня доля природного газа в топливном балансе Москвы приближается к
          100%, что делает энергетический комплекс столицы самым экологически
          чистым в мире.
        </p>
        <p>
          Ведущие роль и место Общества в вопросах жизнеобеспечения города
          Москвы во многом определяются сложившейся структурой потребления газа:
          88% потребителей составляют объекты большой энергетики. Основным
          потребителем АО «МОСГАЗ» является крупнейшее энергоснабжающее
          предприятие города Москвы – ПАО «Мосэнерго», доля потребления которого
          составляет 84%.
        </p>

        <p>
          Структура газопотребления определила специфику развития и
          совершенствования городской газовой сети с акцентом на газопроводы
          высокого давления и системообразующие газорегуляторные пункты большой
          произво- дительности: объекты, эксплуатируемые АО «МОСГАЗ», играют
          роль своеобразных артерий сложнейшего город- ского организма,
          обеспечивающих выработку электрической и тепловой энергии в объемах,
          обеспечивающих потребности города.
        </p>

        <p>
          Основополагающими целями компании, в соответствии с Федеральным
          законом от 31.03.1999 № 69-ФЗ «О газоснабжении в Российской
          Федерации», являются обеспечение надежного, бесперебойного и
          безопасного снабжения газом объектов городского хозяйства и населения
          города Москвы.
        </p>

        <p>
          Общество в своей работе активно использует энергосберегающие
          технологии, мероприятия, проводимые в этой области, позволяют
          сократить водопотребление, расход электрической и тепловой энергии,
          горюче-смазочных материалов, а также природного газа.
        </p>

        <SinglePhoto>
          <StaticImage
            src="../images/part-01/1.2.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            idkey="i1.2"
          />
        </SinglePhoto>

        <p>
          Для обеспечения бесперебойной транспортировки газа до потребителей и
          недопущения аварийных ситуаций на объектах, для поддержания в
          работоспособном состоянии своих основных фондов Общество регулярно
          проводит профилактические работы по облуживанию сетей и оборудования.
          Ремонтные работы проводятся в соответствии с графиками проведения
          планово-предупредительного ремонта. При выявлении повреждений сети
          данный объект включается в программу реконструкции Общества. При
          реконструкции газопроводов используются самые современные и
          эффективные методики и технологии.
        </p>

        <p>
          В 2021 году АО «МОСГАЗ» в рамках производственной программы были
          выполнены работы по прокладке газовых сетей общей протяженностью 84,18
          км: реконструировано 49,95 км, новое строительство 34,23 км,
          реконструировано 9 ГРП малой производительности с применением
          модульных блоков полной заводской готовности. Построен ГРП «Ликовский»
          для газификации Филимонковского и Первомайского поселений ТиНАО города
          Москвы, реконструирован ГРП № М-5 «Люблино 1» средней
          производительности, предназначенный для газоснабжения социальных,
          промышленных объектов и объектов энергетики, а также на нужды жилого
          фонда ЮВАО города Москвы.
        </p>

        <p className="mb-1">
          <strong>
            АО «МОСГАЗ» продолжена реализация масштабных проектов по развитию
            газораспределительной сети города Москвы:
          </strong>
        </p>
        <ul className="list-disc list-outside">
          <li>
            <p>
              в стадии завершения комплекс работ по{" "}
              <strong>газификации поселка Толстопальцево</strong> (район
              Внуково, ЗАО г. Москвы). Произведена прокладка сетей высокого,
              среднего и низкого давлений общей протяженностью 52,4 км, а также
              завершено строительство 2 ГРПБ и 3 ШРП.
            </p>
            <p>
              Решение данной задачи позволит обеспечить газоснабжением объекты
              не только поселка Толстопальцево, но и близлежащих деревень и СНТ
              (Постниково, СПК «Поляна-98», СНТ «Фронтовики», СНТ «Луч», СНТ
              «Заря», СНТ «Искра», СНТ «Ручеек», а также СНТ «Стрелец»).
            </p>
          </li>

          <Short
            text="Реконструкция газопроводов-дюкеров"
            link="https://www.mos-gaz.ru/projects/482"
            short_id="short_1-1"
            className="pt-20 w-[250px]"
          />
          <li>
            <p>
              с 2018 года реализуются предложенная АО «МОСГАЗ» и поддержанная
              Правительством Москвы{" "}
              <strong>
                программа развития системы газоснабжения на территории ТиНАО
                города Москвы
              </strong>
              . Программой предусмотрена прокладка системообразующих
              газопроводов протяженностью 343 км, а также строительство 11
              пунктов редуцирования газа, позволяющих обеспечить подачу газа
              потребителям ТиНАО от источников, расположенных на территории
              города.
            </p>
            <p>
              Выполнение программы позволит сформировать единую систему
              транспортировки газа на территории всех административных округов
              Москвы и обеспечить поставку необходимых объемов природного газа к
              районам перспективной застройки.
            </p>
          </li>
          <li>
            <p>
              с 2018 года продолжаются{" "}
              <strong>работы по перекладке 13 газопроводов-дюкеров</strong>{" "}
              (переходов через водные преграды) общей протяженностью 9,7 км,
              проложенных в центральных районах города в 1939–1965 гг. и
              нуждающихся в реконструкции по сроку эксплуатации.
            </p>
            <p>
              дающихся в реконструкции по сроку эксплуатации. Новые газопроводы
              диаметром 820 мм прокладываются в герметичных футлярах диаметром
              1200 мм на глубине от 10 метров, прослужат более 40 лет, а
              окупятся, по расчетам специалистов, за 14–15 лет.
            </p>
          </li>
        </ul>
      </TextBlock>
      <ImageSlider>
        <Slide>
          <StaticImage
            src="../images/part-01/slide.1/2Z4A8898.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            height={300}
          />
        </Slide>
        <Slide>
          <StaticImage
            src="../images/part-01/slide.1/120A0624.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            height={300}
          />
        </Slide>
        <Slide>
          <StaticImage
            src="../images/part-01/slide.1/2Z4A9561.crop.1.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            height={300}
          />
        </Slide>
        <Slide>
          <StaticImage
            src="../images/part-01/slide.1/2Z4A9430.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            height={300}
          />
        </Slide>
      </ImageSlider>
      <TextBlock>
        <p>
          «Автозаводский» дюкер на Нагатинской набережной стал первым
          завершенным объектом по программе модернизации дюкеров. Новый тоннель
          для газопровода протяженностью 341 метр был готов с опережением срока,
          в середине июля 2019 года. По завершении монтажа вертикальных участков
          газопровода, соединяющих подводную часть дюкера с основной
          магистралью, «Автозаводский» дюкер в начале марта 2020 года был врезан
          в сеть.{" "}
          <strong>
            Перекладка газопроводов-дюкеров – стратегическая задача Общества.
          </strong>
        </p>

        <p>
          В отчетном периоде АО «МОСГАЗ» оперативно выполнялись работы на
          объектах газораспределительной сети, попадающих в зону реализации
          мероприятий, предусмотренных{" "}
          <strong>
            Государственной программой города Москвы «Развитие городской среды».
          </strong>{" "}
          Минимизация неудобств для жителей обеспечивалась применением
          бестраншейных технологий: проколов, закрытых переходов и метода
          санации. Работы по реконструкции сетей проводились без отключения
          потребителей от газоснабжения.
        </p>
        <p>
          Эксплуатация объектов сети газораспределения осуществляется АО
          «МОСГАЗ» в соответствии с требованиями Федерального закона от
          21.07.1997 № 116-ФЗ «О промышленной безопасности опасных
          производственных объектов», Правил безопасности сетей
          газораспределения и газопотребления, Технического регламента о
          безопасности сетей газораспределения и газопотребления, ГОСТ Р
          54983-2012 «Системы газораспределительные. Сети газораспределения
          природного газа. Общие требования к эксплуатации. Эксплуатационная
          документация», а также других нормативных документов.
        </p>
        <p>
          Надежное газоснабжение гарантирует стабильную работу
          теплоэлектроцентралей и котельных, которые, в свою очередь, снабжают
          электрической и тепловой энергией промышленные объекты учреждения и
          жилой фонд города.
        </p>
        <p>
          АО «МОСГАЗ» является неотъемлемой частью Комплекса городского
          хозяйства города Москвы, эффективная работа коллектива которого вносит
          весомый вклад в экономическую жизнь и социальную сферу столицы.
        </p>

        <Header4>
          Программа ремонтного и профилактического обслуживания
          газораспределительной сети
        </Header4>

        <p>
          Одним из важнейших условий обеспечения бесперебойного и качественного
          газоснабжения потребителей, а также безопасной и безаварийной работы
          газораспределительной сети города Москвы является неукоснительное
          выполнение требований действующих стандартов, норм и правил в сфере
          эксплуатации опасных производственных объектов.
        </p>
      </TextBlock>

      <ImageSlider>
        <Slide>
          <StaticImage
            src="../images/part-01/slide.2/slide.1.2.main.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            height={300}
          />
        </Slide>
        <Slide>
          <StaticImage
            src="../images/part-01/slide.2/00MAX_4760.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            height={300}
          />
        </Slide>
        <Slide>
          <StaticImage
            src="../images/part-01/slide.2/00MAX_4766.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            height={300}
          />
        </Slide>
        <Slide>
          <StaticImage
            src="../images/part-01/slide.2/00MAX_4779.jpg"
            loading="eager"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt=""
            height={300}
          />
        </Slide>
      </ImageSlider>
      <TextBlock>
        <p className="mb-1">
          Программа ремонтного и профилактического обслуживания сформирована с
          учетом требований законодательства Российской Федерации в области
          эксплуатации систем газоснабжения и исходя из оценки фактического
          технического состояния элементов газораспределительных сетей. В целях
          повышения надежности и эффективности работы газового оборудования,
          обеспечения содержания городской газораспределительной сети в
          работоспособном состоянии Обществом в 2021 году выполнен комплекс
          мероприятий:
        </p>

        <ul className="list-disc list-outside">
          <li>
            <p>
              техническое диагностирование наружных газопроводов, газового
              оборудования и строительных конструкций пунктов редуцирования газа
              (ПРГ);
            </p>
          </li>
          <li>
            <p>
              техническое обслуживание, текущий и капитальный ремонт
              газопроводов, сооружений, технических и технологических устройств;
            </p>
          </li>
          <li>
            <p>модернизация газового оборудования;</p>
          </li>
          <li>
            <p>
              автоматизация производственных процессов, целью которой является
              повышение контроля состояния сетей и оборудования в текущем режиме
              и оперативное реагирование для устранения неисправностей.
            </p>
          </li>
        </ul>

        <p>
          Плановые задания 2021 года по подготовке городской системы
          газоснабжения к осенне-зимнему периоду, включающие в себя: ремонт
          основного и вспомогательного оборудования, зданий и сооружений,
          техническое освидетельствование и диагностику оборудования, выполнены
          своевременно и в полном объеме, что обеспечило устойчивое и
          безаварийное функционирование городской газораспределительной сети.
        </p>
        <p>
          По результату выполненных мероприятий АО «МОСГАЗ» получен паспорт
          готовности к работе в осенне-зимний период 2021–2022 гг.
        </p>

        <Header3>1.1.2. ОПИСАНИЕ КОНКУРЕНТНОГО ОКРУЖЕНИЯ ОБЩЕСТВА</Header3>
        <p>
          АО «МОСГАЗ» является субъектом естественной монополии в сфере оказания
          услуг по транспортировке газа на территории города Москвы.
        </p>
        <p>
          Конкурентное окружение Общества в 2021 году, в сравнении с 2020 годом,
          не изменилось.
        </p>
        <p>
          Московскую городскую систему газоснабжения и газораспределения
          обслуживают три основные газораспределительные организации: АО
          «МОСГАЗ», АО «Газпром газораспределение» и АО «Мособлгаз».
        </p>
        <p>
          Основным фактором конкурентоспособности Общества в области услуг по
          транспортировке газа является наличие разветвленной сети газопроводов,
          способствующей удовлетворению спроса на передачу газа на большей части
          территории Москвы и ряда населенных пунктов Московской области.
          Первоочередная значимость этого фактора объясняется тем, что создание
          конкурентной газовой сети крайне маловероятно.
        </p>
        <p>
          Другой фактор – наличие развитой инфраструктуры, обеспечивающей
          качественную эксплуатацию газовых сетей.
        </p>

        <Header2>1.2. ВИДЫ ДЕЯТЕЛЬНОСТИ</Header2>
        <p>
          Основным видом деятельности АО «МОСГАЗ» является оказание услуг
          транспортировки газа (распределение газообразного топлива по
          газораспределительным сетям).
        </p>
        <p>
          Общество также осуществляет иные виды деятельности, предусмотренные
          Уставом: эксплуатация газораспределительной системы и сооружений на
          ней, строительство, реконструкция, капитальный ремонт и техническое
          перевооружение газовых сетей и объектов газового хозяйства,
          локализация и ликвидация аварийных ситуаций и аварий на газовых сетях
          и сооружениях, технологическое присоединение объектов капитального
          строительства к эксплуатируемым газовым сетям, техническое
          обслуживание объектов газового хозяйства, ремонт и реконструкция
          внутридомового газового оборудования и внутридомовых систем
          газоснабжения.
        </p>
        <p>
          Среди значимых направлений можно также выделить следующие: разработка
          перспективных планов развития системы газоснабжения городских
          территорий, решение задач научно-технического и инновационного
          развития в сфере газоснабжения, внедрение нового оборудования,
          технологий и материалов.
        </p>

        <Header2>1.3. СТРАТЕГИЧЕСКИЕ ПРИОРИТЕТЫ ОБЩЕСТВА</Header2>
        <article>
          <Short
            text="Генеральная схема газоснабжения города Москвы"
            link="https://www.mos-gaz.ru/gas-supply/scheme/"
            short_id="short_1-3"
            className="w-[250px]"
          />
          <p>
            Во исполнение поручений Президента Российской Федерации В.В. Путина
            от 31.05.2020 № Пр-907 и от 02.05.2021 № Пр-753 по внедрению
            социально ориентированной и экономически эффективной системы
            газификации и газоснабжения на территории субъектов в 2021 году
            Общество активно приступило к реализации мероприятий социальной
            газификации (догазификации) города Москвы.
          </p>

          <p>
            Актуализированной Программой газификации жилищно-коммунального
            хозяйства, промышленных и иных организаций города Москвы на
            2022–2031 годы предусмотрено строительство системообразующих
            газопроводов и газораспределительных пунктов, охватывающих всю
            территорию ТиНАО города Москвы.
          </p>
          <p>
            В рамках задачи догазификации в Программу газификации Москвы на
            данном этапе включены мероприятия по строительству в 2022–2023 гг.
            подводящих газопроводов к 41 населенному пункту и СНТ с общим
            количеством негазифицированных домовладений – 2824.
          </p>
          <p>
            Мероприятия по догазификации домовладений планируется включать в
            Программу газификации Москвы по мере строительства системообразующих
            и подводящих к населенным пунктам газопроводов, а также на основании
            поступивших заявок жителей.
          </p>
        </article>
        <Header2>
          1.4. ОСНОВНЫЕ ФАКТОРЫ РИСКА И СИСТЕМА УПРАВЛЕНИЯ РИСКАМИ
        </Header2>
        <article>
          <Short
            text="МОСГАЗ сегодня"
            image="../images/shorts/1.4.jpg"
            link="https://www.mos-gaz.ru/about/"
            short_id="short_1-4"
            className="w-[250px]"
          />
          <p>
            Повышение эффективности деятельности Общества и снижение вероятности
            имущественных потерь не могут достигаться без проведения мониторинга
            рисков, возникающих в процессе деятельности компании.
          </p>

          <p>
            Деятельность АО «МОСГАЗ» в силу своих функциональных особенностей и
            территориальной распределенности сталкивается с принципиально
            различными видами рисков отраслевого и производственного характера.
            Системная минимизация этих рисков – необходимое условие достижения
            целей газораспределительных организаций, обеспечения надежности их
            функционирования и экономической эффективности.
          </p>

          <p>
            Управление рисками Общества основывается на постоянном мониторинге
            внешней и внутренней среды, анализе угроз и возможностей, влияющих
            на достижение как экономических, так и социальных целей компании.
          </p>
        </article>
        <p className="mb-1">
          <strong>
            Основными задачами системы управления рисками являются:
          </strong>
        </p>

        <ul className="list-outside list-disc">
          <li>
            <p>обеспечение разумной уверенности в достижении целей Общества;</p>
          </li>
          <li>
            <p>
              обеспечение эффективности финансово-хозяйственной деятельности и
              экономичного использования ресурсов Общества;
            </p>
          </li>
          <li>
            <p>выявление рисков Общества и управление такими рисками;</p>
          </li>
          <li>
            <p>обеспечение сохранности активов Общества;</p>
          </li>
          <li>
            <p>
              обеспечение полноты и достоверности бухгалтерской (финансовой),
              статистической, управленческой и другой отчетности Общества;
            </p>
          </li>
          <li>
            <p>
              контроль соблюдения законодательства, а также внутренних политик,
              регламентов и процедур Общества.
            </p>
          </li>
        </ul>
        <p>
          Целью системы управления рисками АО «МОСГАЗ» является определение всех
          рисков, связанных с деятельностью Общества, и осуществление
          своевременного реагирования.
        </p>
        <p>
          Для обеспечения экономической безопасности, высокого уровня
          конкурентоспособности и решения стратегических задач АО «МОСГАЗ»
          реализует комплексный подход к оценке рисков, что обеспечивает
          формирование консолидированной реальной оценки соответствующего риска
          Общества и является основой принятия своевременных и правильных
          стратегических управленческих решений.
        </p>

        <Header3>1.4.1. ОТРАСЛЕВЫЕ РИСКИ</Header3>
        <Header4>Регуляторный риск (Тарифное регулирование)</Header4>

        <p>
          Эксплуатация газораспределительной системы, обеспечение ее надежности
          и безопасности, оказание услуг, связанных с подачей газа потребителям,
          – один из основных видов деятельности АО «МОСГАЗ».
        </p>

        <p>
          Риски, связанные с решениями органов власти в области тарифного
          регулирования, являются ключевыми. В частности, к ним относятся:
          установление тарифов на транспортировку газа или других регулируемых
          цен на услуги Общества (технологическое присоединение) ниже
          экономически обоснованного уровня, пересмотр установленных тарифов в
          течение периода регулирования.
        </p>

        <p>
          В целях снижения данного риска предпринимаются меры к повышению
          эффективности деятельности всех структурных подразделений Общества:
          вводится строгое лимитирование расходов, используются все возможные
          виды экономии, определяются наиболее приоритетные направления для
          финансирования.
        </p>

        <p>
          Общество непрерывно взаимодействует с регулирующими органами по
          вопросам предоставления обосновывающих материалов, подтверждающих
          затраты по регулируемым видам деятельности.
        </p>

        <p>
          Так, в течение 2021 года Обществом направлялись в ФАС России
          аналитические расчеты и обосновывающие материалы касательно пересмотра
          и установления экономически обоснованного размера тарифа на
          транспортировку газа. В декабре 2021 года утверждены тарифы на
          транспортировку газа на долгосрочный период 2022–2026 гг. (Приказ ФАС
          России от 27.12.2021 № 1533/21).
        </p>

        <Header4>
          Риск сезонного изменения спроса (влияние природного фактора)
        </Header4>

        <p>
          Риск обусловлен сезонностью спроса на потребляемый газ зависимостью
          полезного отпуска от колебаний температуры наружного воздуха.
        </p>

        <p>
          В целях снижения степени влияния данного риска Общество осуществляет
          следующие мероприятия: модернизация объектов газового хозяйства в
          целях увеличения запаса производственных мощностей и планирование
          ремонтной программы с учетом сезонности спроса.
        </p>

        <p>
          Повышение среднегодовых температур влияет на динамику снижения
          ежегодного объема транспортировки газа, что в свою очередь может
          приводить к снижению выручки относительно запланированных показателей.
        </p>

        <Header3>1.4.2. ПРОИЗВОДСТВЕННЫЕ РИСКИ</Header3>

        <p className="mb-1">
          Деятельность АО «МОСГАЗ» потенциально сопряжена с возможностью
          возникновения техногенных ситуаций на объектах газового комплекса.
          Основными факторами возникновения производственно-технических рисков
          являются:
        </p>

        <ul className="list-disc list-outside">
          <li>
            <p>
              ухудшение эксплуатационных и экономических показателей газового
              оборудования, связанное со старением оборудования и износом
              основных фондов Общества;
            </p>
          </li>
          <li>
            <p>наступление неблагоприятных экологических последствий;</p>
          </li>
          <li>
            <p>риск производственного травматизма персонала;</p>
          </li>
          <li>
            <p>угроза возникновения аварий системного характера;</p>
          </li>
          <li>
            <p>
              механические повреждения газопроводов сторонними организациями.
            </p>
          </li>
        </ul>

        <p>
          Для предотвращения данных рисков и факторов их возникновения
          АО«МОСГАЗ» осуществляет комплекс мер по обеспечению надежности
          оборудования и сооружений, включающий в себя выполнение ремонтных
          работ в полном объеме, внедрение современных методов диагностики без
          остановки оборудования, постоянную оптимизацию объема запасных частей,
          страхование основных средств, введение тендерного отбора сервисных и
          снабженческих организаций. Управление рисками в отношении промышленной
          безопасности в общей структуре управления производственными рисками АО
          «МОСГАЗ» обеспечивается соблюдением федерального законодательства в
          области промышленной безопасности.
        </p>

        <Header3>1.4.3. ФИНАНСОВЫЕ РИСКИ</Header3>
        <Header4>Ценовые риски</Header4>
        <p>
          Ценовым рискам (рискам колебания рыночных цен) подвержены расценки по
          прочим видам деятельности Общества, не подлежащие тарифному
          регулированию, а также цены поставщиков на услуги, материалы и
          комплектующие.
        </p>

        <p>
          Данный вид риска расценивается для Общества как минимальный, поскольку
          реализация производится по рыночным ценам, имеющим положительную
          динамику, а выбор поставщиков осуществляется на конкурентной основе.
        </p>

        <p>
          Риск недостижения плановой рентабельности сделок минимизируется путем
          диверсификации видов деятельности и увеличения точности
          прогнозирования, планирования и ценообразования.
        </p>

        <Header4>Валютные риски</Header4>

        <p>
          В отчетном периоде подверженность АО «МОСГАЗ» риску негативного
          изменения денежных потоков вследствие изменения курса обмена
          иностранных валют минимальна ввиду отсутствия обязательств Общества,
          номинированных в иностранной валюте.
        </p>

        <Header4>Кредитные риски</Header4>

        <p className="mb-1">
          В рамках управления кредитным риском Общество на постоянной основе:
        </p>

        <ul className="list-disc list-outside">
          <li>
            <p>
              проводит анализ контрагентов на предмет платежеспособности и
              финансовой устойчивости;
            </p>
          </li>
          <li>
            <p>
              осуществляет мониторинг уровня дебиторской задолженности и
              реализует мероприятия, направленные на снижение уровня дебиторской
              задолженности;
            </p>
          </li>
          <li>
            <p>
              использует авансовую схему расчетов при взаимодействии с
              подрядными организациями.
            </p>
          </li>
        </ul>

        <Header4>Процентные риски</Header4>
        <p>
          Общество не привлекает внешних заимствований и не приобретает
          краткосрочных финансовых инструментов, таким образом, процентный риск
          (риск изменения процентных ставок) не влияет на размер обязательств и
          расходов Общества и может оказать влияние лишь на сумму
          запланированных к получению доходов по договорам размещения
          временно–свободных средств по договорам неснижаемого остатка в
          коммерческих банках.
        </p>

        <Header4>Инфляционные риски</Header4>
        <p>
          Риск влияния инфляции может возникнуть в случае, когда получаемые
          денежные доходы обесцениваются с точки зрения реальной покупательной
          способности денег быстрее, чем растут номинально. Рост инфляции влияет
          на финансовые результаты деятельности Общества, может привести к
          увеличению затрат Общества и, как следствие, падению прибыли и
          рентабельности его деятельности.
        </p>

        <p>
          Отрицательное влияние инфляции на финансово-экономическую деятельность
          Общества может возникнуть на стыке с рисками тарифного регулирования:
          в случае если прогнозный уровень инфляции, используемый при расчете
          тарифа по основному виду деятельности, окажется ниже реального роста
          цен на товары, услуги и комплектующие, используемые в производстве.
        </p>

        <Header3>1.4.4. ПРАВОВЫЕ РИСКИ</Header3>
        <Header4>Антимонопольные риски</Header4>
        <p>
          Деятельность АО «МОСГАЗ» в условиях естественной монополии
          обуславливает наличие существенных антимонопольных рисков.
        </p>
        <p>
          Риски сформированы и обусловлены: практикой ФАС России, изменением
          антимонопольного законодательства, структурой рынка оказания услуг,
          подверженных антимонопольным ограничениям, недобросовестной
          конкуренцией и ценовым регулированием, организацией бизнес-процессов с
          учетом антимонопольных ограничений, организацией конкурентных процедур
          отбора по правилам действующего законодательства.
        </p>
        <p className="mb-1">
          В целях минимизации обозначенных рисков АО «МОСГАЗ» проводит:
        </p>

        <ul className="list-disc list-outside">
          <li>
            <p>
              <strong>выявление и оценку антимонопольных рисков</strong>:
              сегментация деятельности Общества по рынкам присутствия; анализ
              практики ФАС, специфики бизнес-процессов и экономического
              обоснования цен на товары/услуги Общества. Оценка выявленных
              рисков по их существенности;
            </p>
          </li>
          <li>
            <p>
              <strong>реагирование на антимонопольные риски</strong>:
              определение мер реагирования (в зависимости от существенности
              риска); разработка мероприятий/контрольных процедур по управлению
              рисками и определение ответственных;
            </p>
          </li>
          <li>
            <p>
              <strong>мониторинг антимонопольных рисков</strong>: определение
              ключевых индикаторов антимонопольных рисков (и нарушений);
              мониторинг деятельности ФАС и изменений в антимонопольном
              законодательстве, изменений во внутренней структуре и
              бизнес-процессах Общества; выявление реализовавшихся рисков и их
              причин.
            </p>
          </li>
        </ul>

        <p>
          В составе практических мер реагирования на антимонопольные риски АО
          «МОСГАЗ» реализует: разработку типовых форм договоров по
          соответствующим рынкам присутствия; применение единых правил
          формализации и типовых формулировок при взаимодействии с
          контрагентами; проведение антимонопольной экспертизы договоров;
          применение единых подходов к ценообразованию по соответствующим рынкам
          присутствия.
        </p>

        <Header4>Риски нормативного регулирования</Header4>

        <p>
          Ключевые виды деятельности Общества: транспортировка газа,
          деятельность по технологическому присоединению объектов капитального
          строительства к сетям газораспределения, а также по техническому
          обслуживанию внутридомового и внутриквартирного газового оборудования
          – являются объектом государственного регулирования.
        </p>

        <p>
          Изменение нормативного регулирования может не только повлиять на
          расходы Общества по основным направлениям его деятельности и изменить
          конкурентную среду, но и приводить к прямым убыткам, вызванным
          привлечением к административной и/или иной ответственности, в случае
          несоответствия установленным нормативным требованиям.
        </p>

        <p>
          Активная законодательная позиция Общества обеспечивает максимальное
          нивелирование таких рисков. Силами АО «МОСГАЗ» через профильные
          министерства и ведомства, комитеты Государственной Думы, а также
          курирующие департаменты города Москвы инициируются проекты нормативных
          актов, направленных как на защиту законных прав и интересов Общества,
          так и на снятие излишних административных барьеров. На стадии
          разработки проектов АО «МОСГАЗ» привлекается в состав рабочих групп
          для целей подготовки правовых заключений и внесения предложений по
          изменению действующих нормативных правовых актов.
        </p>

        <p>
          Инициативная позиция Общества в сфере законодательной деятельности
          осуществляется в условиях сотрудничества с органами законодательной и
          исполнительной власти Российской Федерации и города Москвы.
        </p>

        <Header4>Риски судебного производства</Header4>
        <p>
          Данная категория рисков связана с участием Общества в судебных
          разбирательствах: судебные споры с участием контрагентов в связи с
          осуществлением предпринимательской и иной экономической деятельности,
          привлечение к участию в спорах представителей органов государственной
          и муниципальной власти при осуществлении последними контроля
          (надзора).
        </p>

        <p>
          Правовое обеспечение деятельности Общества проявляется не только во
          взаимодействии с контрагентами, но прежде всего во взаимодействии с
          государственными контролирующими органами, нивелируя на досудебной
          стадии возможные судебные и административные риски.
        </p>

        <p>
          Обществом реализуются мероприятия, направленные на минимизацию
          судебно-правовых рисков: эффективное внесудебное урегулирование
          споров, анализ причин возникновения административных и судебных дел,
          мониторинг судебно-арбитражной практики; комплаенс-проверка
          контрагентов на стадии заключения договоров; разработка и актуализация
          типовых форм документов и сопровождение мероприятий государственного
          контроля (надзора).
        </p>

        <NaviNext
          next="/part-2"
          title="РАЗДЕЛ 2. ОСНОВНЫЕ ПРОИЗВОДСТВЕННЫЕ ПОКАЗАТЕЛИ"
        ></NaviNext>
      </TextBlock>
    </Tail>
  );
};

export default Part1;

export const Head = () => <Seo title="РАЗДЕЛ 1. ОБЩИЕ СВЕДЕНИЯ" />;
