import React from "react";
const Slide = ({ children }) => {
  return (
    <div className="m-1 md:m-5 pr-1">
      {children}
    </div>
  );
};

export default Slide;
