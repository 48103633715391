import * as React from "react"
import * as d3 from "d3"
import { fIntG, fFloatG } from "../helper"

const getNextColor = colors => {
  let i = 0

  return () => {
    i++
    if (i === colors.length) {
      i = 0
    }
    return colors[i]
  }
}

const HistoOld = ({ idkey }) => {
  const data = [
    {
      date: "31.12.2010",
      ghd: 74.7,
      gmd: 274.35,
      gld: 994.26,
    },

    {
      date: "31.12.2011",
      ghd: 78.7,
      gmd: 267.77,
      gld: 1008.66,
    },

    {
      date: "31.12.2012",
      ghd: 84.81,
      gmd: 262.08,
      gld: 1040.94,
    },

    {
      date: "31.12.2013",
      ghd: 84.57,
      gmd: 248.47,
      gld: 1035.8,
    },
    {
      date: "31.12.2014",
      ghd: 84.29,
      gmd: 232.61,
      gld: 1027.14,
    },
    {
      date: "31.12.2015",
      ghd: 84.31,
      gmd: 233.7,
      gld: 1009.15,
    },
    {
      date: "31.12.2016",
      ghd: 80.77,
      gmd: 227.98,
      gld: 979.69,
    },
    {
      date: "31.12.2017",
      ghd: 77.99,
      gmd: 215.41,
      gld: 947.49,
    },
    {
      date: "31.12.2018",
      ghd: 62.19,
      gmd: 202.55,
      gld: 930.13,
    },
    {
      date: "31.12.2019",
      ghd: 61.13,
      gmd: 182.36,
      gld: 906.57,
    },
    {
      date: "31.12.2020",
      ghd: 56.8,
      gmd: 167.98,
      gld: 905.85,
    },
    {
      date: "31.12.2021",
      ghd: 53.85,
      gmd: 164.51,
      gld: 898.54,
    },
  ]

  const margin = { top: 10, right: 30, bottom: 50, left: 50 },
    width = 750 - margin.left - margin.right,
    height = 450 - margin.top - margin.bottom

  React.useEffect(() => {
    const selector = `.HistoOld-${idkey}`
    document.querySelector(selector).innerHTML = ""
    const svg = d3
      .select(selector)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    const subgroups = ["ghd", "gmd", "gld", "all"]
    var groups = d3.map(data, d => {
      return d.date
    })

    var x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2])

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSize(0))
      .selectAll("text")
      .attr("transform", "translate(10,0)rotate(-45)")
      .style("text-anchor", "end")

    var y = d3.scaleLinear().domain([0, 1700]).range([height, 0])
    svg.append("g").call(d3.axisLeft(y).tickFormat(fIntG))

    var xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.05])

    var color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range(["#405790", "#aaa", "#ccc", "#ffa500"])

    const g = svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(data)
      .enter()
      .append("g")
      .attr("transform", d => {
        return "translate(" + x(d.date) + ",0)"
      })

      .selectAll("rect")
      .data(d => {
        return subgroups.map(key => {
          if (key == "all")
            return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .enter()
      .append("rect")
      .attr("x", d => {
        return xSubgroup(d.key)
      })
      .attr("y", d => {
        return y(d.value)
      })
      .attr("width", xSubgroup.bandwidth())
      .attr("height", d => {
        return height - y(d.value)
      })
      .attr("fill", d => {
        return color(d.key)
      })
      .exit()
      .data(d => {
        return subgroups.map(key => {
          if (key == "all")
            return { key: key, value: d["ghd"] + d["gmd"] + d["gld"] }

          return { key: key, value: d[key] }
        })
      })
      .join("text")

      .attr("x", d => {
        return xSubgroup(d.key) + 5
      })
      .attr("y", d => {
        return y(d.value) + 5
      })
      .text(d => fFloatG(d.value))
      .style("font-size", "12px")
      .style("font-weight", "bold")

      .attr("transform", d => {
        return `rotate(-90, ${xSubgroup(d.key)}, ${y(d.value)})`
      })

    svg
      .append("line")
      .attr("x1", 30)
      .attr("y1", 50)
      .attr("x2", 650)
      .attr("y2", 150)
      .attr("stroke", "gray")
      .attr("stroke-dasharray", "10 15")
      .attr("stroke-width", "2")
  }, [idkey])

  const ldata = [
    {
      label: "газопровод высокого давления",
      color: "",
    },
    {
      label: "газопровод среднего давления",
      color: "",
    },
    {
      label: "газопровод низкого давления",
      color: "",
    },
    {
      label: "общая протяженность",
      color: "",
    },
  ]

  const lcolor = [
    "bg-[#405790]",
    "bg-[#aaa]",
    "bg-[#ccc]",
    "bg-[#ffa500]",
  ]
  const getLegendColor = getNextColor(lcolor)

  return (
    <>
      <div className={`HistoOld-${idkey}`}></div>
      <div className="max-w-4xl m-auto">
        <ul className="list-none pl-0 text-300 my-5 text-sm">
          {ldata.map((el, i) => (
            <li key={i}>
              <span className={`legend-pseudo ${lcolor[i]}`}></span>
              {el.label}
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}

export default HistoOld
